import {
  takeEvery,
  put,
  delay,
  select,
  spawn,
  take,
  race,
  all,
} from 'redux-saga/effects';
import { LOGGED_IN } from '../modules/auth/actions';
import { syncContactsMe } from '../modules/contacts/actions';
import { syncCompanies } from '../modules/companies/actions';
import { fetchProjects } from '../modules/projects/actions';
import { fetchUserMe } from '../modules/users/actions';
import { purgeDriveItems } from '../modules/documents/actions';
import { resetMeta, resetPrioCache } from '../actions';
import { fetchOfficeMe } from '../modules/users/actions/officeMe';
import {
  getCompanyOffset,
  getContactOffset,
  getCurrentPrioVersion,
  getUserMe,
} from '../apps/main/rootReducer';
import { mapLastVersions } from '../modules/main/NewPrioVersionModal';
import packageJson from '../../package.json';
import { fetchRemoteFolders } from '../modules/documents/actions/remoteFolders';
import { fetchNotifications } from '../modules/notifications/actions';
import { fetchUserSettings } from '../modules/userSettings/actions/index';
import { deleteLocalMessages } from '../modules/mail/actions/actionControllers/messageActionController';
import { deleteAllLocalDrafts } from '../modules/mail/actions/actionControllers/draftsActionController';
import { closeWidgetArea } from '../modules/widgetArea/actions';
import i18n from '../i18n';
import { notification } from 'antd';
import { dispatchSaga } from '../util/sagas';
import {
  FETCH_USER_REGISTRATION_STATE_COMMIT,
  FETCH_USER_REGISTRATION_STATE_REQUEST,
  FETCH_USER_REGISTRATION_STATE_ROLLBACK,
} from '../modules/users/actions/registrationState';
import { purgePrioFileUploadList } from '../modules/documents/actions/uploadLists';
import { fetchAiSetting } from '../modules/settings/actions/aiSetting';

const viewPattern =
  /\/view\/((\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)|me)\/(message|composer)/;

function* initialDataFetching() {
  try {
    const { error, registrationAndLogginCompleted } = yield race({
      registrationAndLogginCompleted: all([
        take(FETCH_USER_REGISTRATION_STATE_COMMIT),
        take(LOGGED_IN),
      ]),
      error: take([FETCH_USER_REGISTRATION_STATE_ROLLBACK]),
    });

    if (registrationAndLogginCompleted && !error) {
      const prioVersion = yield select(getCurrentPrioVersion);
      const shouldFetch = !(
        prioVersion !== packageJson.version &&
        !!mapLastVersions(prioVersion, packageJson.version)
      );
      if (!window.location.href.match(viewPattern)) {
        yield put(deleteLocalMessages());
      } else {
        yield put(deleteAllLocalDrafts());
        yield put(closeWidgetArea());
      }
      yield put(resetPrioCache());

      yield spawn(dispatchSaga, fetchUserMe());
      if (shouldFetch) {
        const userMe = yield select(getUserMe);
        const contactOffset = yield select(getContactOffset);
        const companyOffset = yield select(getCompanyOffset);
        yield spawn(dispatchSaga, fetchUserSettings());
        yield spawn(dispatchSaga, fetchProjects(true));
        yield spawn(dispatchSaga, fetchOfficeMe());
        yield spawn(dispatchSaga, fetchNotifications());
        yield spawn(dispatchSaga, fetchRemoteFolders());
        if (userMe?.myOfficeId && userMe?.id) {
          yield spawn(
            dispatchSaga,
            fetchAiSetting([
              {
                scopeId: userMe?.myOfficeId,
                eSettingScope: 'office',
                keys: ['Cognitive_EnableAiFeatures'],
              },
              {
                scopeId: userMe?.id,
                eSettingScope: 'user',
                keys: ['General_BetaFeaturesEnabled'],
              },
              {
                scopeId: '',
                eSettingScope: 'global',
                keys: ['General_TimeKeepingServicesEnabled'],
              },
              {
                scopeId: '',
                eSettingScope: 'global',
                keys: ['General_HumanResourcesServicesEnabled'],
              },
              {
                scopeId: '',
                eSettingScope: 'global',
                keys: ['General_FinanceServicesEnabled'],
              },
            ])
          );
        }
        yield put(resetMeta());
        if (contactOffset) {
          yield delay(1000);
        }
        yield put(syncContactsMe());
        if (companyOffset) {
          yield delay(3000);
        }
        yield put(syncCompanies());
        //wait 20 seconds before purging drive items
        yield delay(20000);
        yield put(purgeDriveItems());
        yield put(purgePrioFileUploadList());
      }
    } else {
      notification.error({
        message: i18n.t('common:noPrioUser.title'),
        description: i18n.t('common:noPrioUser.message'),
      });
    }
  } catch (error) {
    console.error('Error in initial data load - fetching routine', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('common:errorMessages.initialLoadError'),
    });
  }
}

export default function* watchInitialDataFetching() {
  yield takeEvery(FETCH_USER_REGISTRATION_STATE_REQUEST, initialDataFetching);
}
